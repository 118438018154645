<!-- 提交审批详情-流程 -->
<template>
    <div class="process-wrap">
        <div class="show-all">
            <el-button v-if="showAllBtn && oldData.length > 0" type="text" @click="showAll">查看原流程</el-button>
            <el-button v-if="!showAllBtn && oldData.length > 0" type="text" @click="closeAll">关闭原流程</el-button>
        </div>
        <template v-for="(item1, index1) in showData">
            <div v-for="(item, index) in item1" :key="`${index1}-${index}`" class="process-content">
                <!-- 申请人 -->
                <el-row v-if="index === 0" class="process-item" style="margin-top: 48px">
                    <div style="display:flex;justify-content:space-between;">
                        <div>
                            <p class="process-title">{{ item.designName }}</p>
                            <p class="process-user">{{ item.person[0].name }}</p>

                        </div>
                        <div style="text-align: right">
                            <p class="process-title" style="color: #3C7FFF">已提交</p>
                            <p class="process-user">{{ item.person[0].time }}</p>
                        </div>
                    </div>
                    <div
                        v-if="item.revokeReason"
                        style="display: flex;
                        aligin-item: center;
                        padding-top: 10px;"
                    >
                            <p style="color:#878787;font-size:12px; width:80px; height:45px; line-height:45px;">撤销原因</p>
                            <p class="revokeReason">{{ item.revokeReason }}</p>
                    </div>
                    <div class="circle" style="border-color: #3C7FFF"></div>
                    <div class="top-line"></div>
                    <div class="left-line"></div>
                </el-row>
                <!-- 审批人 -->
                <div v-else-if="item.processType === 2" class="process-item">
                    <el-row type="flex" style="margin-bottom: 20px">
                        <div style="width: 65px">
                            <p class="process-title">{{ item.designName }}</p>
                            <p class="process-user">{{ item.approveMode | filterModeType }}</p>
                        </div>
                        <div>
                            <p class="process-title"
                               :style="{'color': textColor[item.approveResult]}">
                                {{ item.approveResult | filterJumpType }}
                            </p>
                            <p class="process-user">{{ item.time }}</p>
                        </div>
                    </el-row>
                    <div 
                        v-for="(it, index) in item.person" 
                        :key="`person-${index}`"
                        style="margin-top: 15px;"
                    >
                        <div style="display: flex;align-items: center">
                            <text-tooltip
                                :content="it.name"
                                class="process-user"
                                style="width: 60px;text-align: center;margin-right: 20px;flex-shrink: 0"
                            ></text-tooltip>
                            <div class="process-user approve-opinion">{{ it.approveOpinion }}</div>
                            <div style="text-align: right;width: 200px">
                                <p class="process-title" :style="{'color': textColor[it.approveResult]}">
                                    {{ it.approveResult | filterJumpType }}
                                </p>
                                <p class="process-user">{{ it.time }}</p>
                            </div>
                        </div>
                        
                        <div v-if="it.signatureUrl && it.approveResult=='1'" style="display: flex;align-items: center;padding-left:12px;">
                            签名：
                            <img style="height: 48px;width:auto" :src="it.signatureUrl" alt="">
                        </div>
                    </div>
                    <div class="circle" :style="{'border-color': borderColor[item.approveResult]}"></div>
                    <div class="top-line"></div>
                    <div class="left-line"></div>
                </div>
                <!-- 抄送人 -->
                <el-row v-else-if="item.processType === 3" type="flex" class="process-item">
                    <div class="process-title" style="min-width: 65px">{{ item.designName }}</div>
                    <div style="display: flex;flex-wrap: wrap">
                        <div v-for="(item, index) in item.person" :key="`send-people-${index}`" class="send-people-wrap">
                            <div class="process-user">{{ item.name }}</div>
                            <div
                                v-if="item.readStatus"
                                class="process-user send-people-status"
                                :class="{'is-read': item.readStatus === '1'}"
                            >{{ item.readStatus | filterReadStatus }}</div>
                        </div>
                    </div>
                    <div class="circle"></div>
                    <div class="top-line"></div>
                    <div class="left-line"></div>
                </el-row>
                <!-- 结束 -->
                <el-row v-else-if="item.designName === '结束'" type="flex" class="process-end">
                    <div class="process-title" style="width: 65px">结束</div>
                    <!-- 审批状态  0未开始  1（处理人）处理中  2审批通过  3审批拒绝  4已撤销  5审批通过（撤销拒绝）-->
                    <span :style="{'color': endTextColor[item.approveResultCode]}">
                        {{ item.approveResultCode | filterApproveStatus }}
                    </span>
                    <div class="circle" :style="{'border-color': endBorderColor[item.approveResultCode]}"></div>
                    <div class="top-line"></div>
                </el-row>
            </div>
        </template>
    </div>
</template>

<script>

import { TextTooltip } from "common-local";

export default {
    name: "approvalFormData",
    components: { TextTooltip },
    props: {
        oldData: {
            type: Array
        },
        processData: {
            type: Array
        },
    },
    filters: {
        filterJumpType(value) {
            switch (value) {
                case '0':
                    return `待审核`
                case '1':
                    return `同意`
                case '2':
                    return `拒绝`
                default:
                    return ''
            }
        },
        filterApproveStatus(value) {
            switch (value) {
                case '1':
                    return `处理中`
                case '2':
                    return `审批通过`
                case '3':
                    return `审批拒绝`
                case '4':
                    return `已撤销`
                case '5':
                    return `审批通过(撤销拒绝)`
                case '6':
                    return `审批拒绝`
                default:
                    return ''
            }
        },
        filterModeType(value) {
            switch (value) {
                case '1':
                    return '依次审批'
                case '2':
                    return '会签'
                case '3':
                    return '或签'
                default:
                    return ''
            }
        },
        filterReadStatus(value) {
            switch (value) {
                case '1':
                    return '已读'
                case '2':
                    return '未读'
                default:
                    return ''
            }
        }
    },
    data() {
        return {
            showAllBtn: true,
            showData: [],
            // 0待审核  1同意  2 拒绝
            borderColor: ['#E6E6E6', '#47CB94', '#F56C6C'],
            textColor: ['#878787', '#47CB94', '#F56C6C'],
            // 0未开始  1处理中  2审批通过  3审批拒绝  4已撤销  5审批通过（撤销拒绝）
            endTextColor: ['#878787', '#3C7FFF', '#47CB94', '#F56C6C', '#F5B942', '#3C7FFF'],
            endBorderColor: ['#E6E6E6', '#3C7FFF', '#47CB94', '#F56C6C', '#F5B942', '#3C7FFF']
        }
    },
    created() {
        this.showData = [this.processData]
    },
    methods: {
        showAll() {
            this.showAllBtn = false
            this.showData = [...this.oldData, this.processData]
        },
        closeAll() {
            this.showAllBtn = true
            this.showData = [this.processData]
        },
    }
}
</script>

<style scoped lang="scss">
.show-all {
    background: #F5F7FA;
    padding: 0 20px;
    box-sizing: border-box;
}

.process-wrap {
    font-size: 14px;
    padding: 20px 100px;
}

.process-content {
    position: relative;

    .process-item {
        padding: 5px 0 37px 30px;

        .approve-opinion {
            background-color: #fafafa;
            padding: 12px 19px;
            width: 90%;
            border-radius: 6px;
            white-space: normal;
            word-break: break-all;
            min-height: 20px;
        }

        .send-people-wrap {
            display: flex;
            margin-bottom: 8px;
            align-items: center;
            margin-right: 35px;

            .send-people-status {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                padding: 0 4px;
                height: 18px;
                border-radius: 3px;
                background-color: #ffffff;
                border: 1px solid #3c7fff;
                box-sizing: border-box;
                color: #3c7fff;
                font-size: 12px;
                text-align: center;
                transform: scale(0.8);
            }

            .is-read {
                color: #999999;
                border-color: #e6e6e6;
                background-color: #e6e6e6;
            }
        }
    }
}

.process-title {
    padding-bottom: 5px;
    color: #333333;
}

.process-user {
    white-space: nowrap;
    color: #878787;
    font-size: 12px;
}
.revokeReason {
    width: 56%;
    background-color: #fafafa;
    padding: 12px 19px;
    color: #878787;
    font-size: 12px;
    border-radius: 6px;
    white-space: normal;
    word-break: break-all;
    min-height: 20px;
}
.process-end {
    position: relative;
    padding-left: 28px;
    padding-top: 5px;
}

.circle {
    position: absolute;
    top: 10px;
    left: 0;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 2px solid #E6E6E6;
    box-sizing: border-box;
    background-color: #fff;
}

.left-line {
    width: 1px;
    height: calc(100% - 12px);
    position: absolute;
    left: 5px;
    border-left: 1px dashed #DBDBDB;
    top: 22px;
}

.top-line {
    height: 1px;
    width: 8px;
    position: absolute;
    left: 12px;
    border-bottom: 1px dashed #DBDBDB;
    top: 14px;
}

</style>
