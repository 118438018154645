<template>
    <el-form label-width="auto" label-position="right">
        <div v-if="serialNumber" class="serial-number">
            序列号：{{serialNumber}}
        </div>
        <el-row>
            <template v-for="(item, index) in formOptData" >
                <el-form-item
                    v-if="item.show !== false"
                    :label="item.label"
                    :label-width="`${item.labelWidth}px`"
                    :key="index"
                    :style="{width: item.width || '95%'}"
                    :class="{longLabel : item.longLabel}"
                >
                    <el-col :span="item.span" >
                        <el-upload
                            v-if="item.compType === 'upload'"
                            action="#"
                            list-type="picture-card"
                            :file-list="item.value"
                            :disabled="true"
                        >
                            <div
                                slot="file"
                                slot-scope="{file}"
                            >
                                <el-image
                                    :src="file.url"
                                    style="width: 100%; height: 100%"
                                    :preview-src-list="[file.url]"
                                />
                            </div>
                        </el-upload>
                        <div v-else-if="item.compType === 'attachmentUpload'" class="attachment-comp-files">
                            <div
                                v-for="(item, index) in item.value"
                                :key="index"
                                class="attachment-comp-file"
                                @click="handlerPreview(item)">
                                <div class="attachment-comp-file-icon">
                                    <template v-if="['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(item.suffix) || item.type.startsWith('image/')">
                                        <img class="attachment-icon-img attachment-icon-img_img" :src="imgAttachmentImg" alt="">
                                        <span class="attachment-icon-label attachment-icon-label_img">{{ item.suffix }}</span>
                                    </template>
                                    <template v-else-if="['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf'].includes(item.suffix)">
                                        <img class="attachment-icon-img attachment-icon-img_word" :src="imgAttachmentWord" alt="">
                                        <span class="attachment-icon-label attachment-icon-label_word">{{ item.suffix }}</span>
                                    </template>
                                    <template v-else-if="['txt'].includes(item.suffix)">
                                        <img class="attachment-icon-img attachment-icon-img_txt" :src="imgAttachmentTxt" alt="">
                                        <span class="attachment-icon-label attachment-icon-label_txt">{{ item.suffix }}</span>
                                    </template>
                                    <template v-else-if="['zip', 'rar', '7z'].includes(item.suffix)">
                                        <img class="attachment-icon-img attachment-icon-img_zip" :src="imgAttachmentZip" alt="">
                                        <span class="attachment-icon-label attachment-icon-label_zip">{{ item.suffix }}</span>
                                    </template>
                                </div>
                                <span class="attachment-comp-file-name">{{ item.name }}</span>
                                <div v-if="item.downloading === true" style="width: 50px;" v-loading="true" background="transparent"></div>
                                <img v-else :src="downloadImage" alt="download" class="attachment-comp-download" @click.stop="handleDownload(item)">
                            </div>
                        </div>
                        <el-input
                            size="medium"
                            v-else-if="item.compType === 'multiLineText'"
                            :value="item.value"
                            readonly
                            type="textarea"
                            rows="5"
                        ></el-input>
                        <el-date-picker
                            size="medium"
                            v-else-if="
                                [
                                    'leaveStartTime',
                                    'leaveEndTime',
                                    'dateTime',
                                ].includes(item.compType)
                            "
                            v-model="item.value"
                            :format="item['format']"
                            prefix-icon="none"
                            style="width: 100%"
                            readonly
                        />
                        <div
                            v-else-if="item.compType === 'descriptionText'"
                            :style="{
                                'font-size': `${item.fontSize}px`,
                                color: item.color,
                            }"
                        >
                            <div
                                :style="{
                                    'font-size':
                                        item.fontSize < 12
                                            ? '12px'
                                            : `${item.fontSize}px`,
                                    color: item.color,
                                    transform:
                                        item.fontSize < 12
                                            ? `scale(${item.fontSize / 12})`
                                            : 'scale(1)',
                                    'transform-origin': '0 0',
                                }"
                            >
                                {{ item.value }}
                            </div>
                        </div>
                        <div v-else-if="item.compType === 'customTipText'" :style="{ 'font-size': `${item.fontSize}px`, color: item.color, }">
                            <div :style="{ 'font-size': item.fontSize < 12 ? '12px' : `${item.fontSize}px`, color: item.color, 'marginLeft': item.marginLeft ? item.marginLeft : '12px' }">
                                {{ item.value }}
                                <div v-if="item.tipDescription" class="tip_text_class" :style="{ 'font-size': item.fontSize < 12 ? '12px' : `${item.fontSize}px`, color: item.tipcolor, background: item.background, }">
                                    {{ item.tipDescription }}
                                </div>
                            </div>
                        </div>
                        <!-- 宿舍信息 -->
                        <div v-else-if="item.compType === 'dormInfo'" class="dorm-info">
                            <div v-for="(item2, index2) in item.value" :key="index2" class="dorm-info-item">
                                <div class="dorm-info-date">{{ item2.schedulingDate }}</div>
                                <div v-for="(item3, index3) in item2.list" :key="index3">
                                    <div class="dorm-info-shift">{{ item3.roomShift }}</div>
                                    <div class="dorm-info-name">{{ item3.roomNameList.join('、') }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="item.compType == 'kitsSpecialLeave'">
                            <leave-date-settings :disabled="true" :infoData="item.value"></leave-date-settings>
                        </div>
                        <el-input
                            size="medium"
                            v-else
                            :value="item.value"
                            readonly
                        >
                            <template
                                v-if="item.unit"
                                slot="append"
                            >{{ item.unit }}</template>
                        </el-input>
                        <div
                            v-if="leaveDurationDetailsBtnShow(item)"
                            style="font-size: 14px;color: #3C7FFF;cursor: pointer;"
                            @click="handleLeaveDurationDetailsShow"
                        >查看明细</div>
                    </el-col>
                </el-form-item>
            </template>
            <slot></slot>
        </el-row>
    </el-form>
</template>

<script>
import LeaveDateSettings from "@/xkUI/pages/kitsSpecialLeave/src/leave/leaveForm.vue";
export default {
    name: "applyFormData",
    components:{ LeaveDateSettings},
    props: {
        formOptData: {
            type: Array,
        },
        // 序列号
        serialNumber: {
            type: String,
        }
    },
    computed: {
        imgAttachmentImg() {
            return require('@/assets/images/campusOA/attachment-img.png')
        },
        imgAttachmentWord() {
            return require('@/assets/images/campusOA/attachment-word.png')
        },
        imgAttachmentTxt() {
            return require('@/assets/images/campusOA/attachment-txt.png')
        },
        imgAttachmentZip() {
            return require('@/assets/images/campusOA/attachment-zip.png')
        },
        downloadImage() {
            return require('@/assets/images/campusOA/download.png')
        },
    },
    mounted() {
        console.log('传过来的表单详情formOptData',this.formOptData);
    },
    methods: {
        // 预览
        handlerPreview(fileInfo) {
            //你的文档地址
            var file = fileInfo.url
            //XDOC文档预览服务地址
            var xurl = "https://view.xdocin.com/view?src=";
            //传入文档地址
            xurl += encodeURIComponent(file);
            xurl += '&title=' + encodeURIComponent(fileInfo.name)
            //开始预览
            window.open(xurl, '_blank');
        },
        handleDownload(fileInfo) {
            console.log("[handleDownload]fileInfo:", fileInfo);
            this.$message({
                message: '正在下载···',
                type: 'success',
                duration: 2000
            });
            this.downloadFile(fileInfo.url, fileInfo.name, fileInfo);
        },
        getBlob (url, fileInfo) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest()
                xhr.open('GET', url, true)
                xhr.responseType = 'blob'
                xhr.onprogress = (e) => {
                    if (e.loaded < e.total) {
                        this.$set(fileInfo, 'downloading', true)
                    } else {
                        this.$set(fileInfo, 'downloading', false)
                    }
                }
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response)
                    }
                }
                xhr.send()
            })
        },
        saveAs(blob, filename) {
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        },
        downloadFile(url, name, fileInfo) {
            this.getBlob(url, fileInfo).then(blob => {
                this.saveAs(blob, name)
            })
        },
        // 请假时长明细按钮是否展示
        leaveDurationDetailsBtnShow(item) {
            if (item.compType === 'leaveDuration') {
                console.log("[leaveDurationDetailsBtnShow]this.formOptData:", this.formOptData, "item:", item);
                let leaveTypeFormItem = this.formOptData.find(
                    (formItem) => formItem.compType === 'leaveType'
                );
                if (leaveTypeFormItem && leaveTypeFormItem.leaveUnit == 3) {
                    return true
                }
            }
            return false
        },
        // 请假时长明细弹窗打开
        handleLeaveDurationDetailsShow() {
            this.$emit('leaveDurationDetailsShow')
        }
    }
};
</script>

<style scoped lang="scss">
.serial-number {
    font-size: 14px;
    transform: translateX(-38px) translateY(-32px);
}
.el-form {
    ::v-deep .el-form-item {
        margin-bottom: 20px;

        &__label {
            padding-right: 20px;
            font-size: 14px;
            color: #2b2f33;
        }

        &__content {
            .el-input__inner {
                color: #2b2f33;
                background-color: #f8f8f8;

                &:active, &:focus, &:hover {
                    border-color: #dcdee0;
                }
            }

            textarea[readonly] {
                color: #2b2f33;
                background-color: #f8f8f8;

                &:active, &:focus, &:hover {
                    border-color: #dcdee0;
                }
            }


            .el-input-group__append {
                background-color: #dcdee0;
                color: #2b2f33;
                padding: 0 10px;
            }
        }

        .el-upload {
            display: block;
        }

        .el-upload--picture-card {
            display: none;
        }

        .el-date-editor .el-input__inner {
            padding: 0 15px;
        }
        &.longLabel{
            .el-form-item__label{
                line-height: 20px ;
            }
        }
    }
}
.tip_text_class{
    display: inline-block;
    text-align: center;
    width: 80px;
    border-radius: 4px;
    margin-left: 36px;
}
// 宿舍信息
.dorm-info {
    border: 1px solid #EDF0F2;
    border-radius: 4px;
    padding: 13px 9px 18px 17px;
    display: flex;
    flex-direction: column;

    .dorm-info-item {
        .dorm-info-date {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: initial;
        }

        .dorm-info-shift {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: initial;
            margin-top: 10px;
        }

        .dorm-info-name {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
            line-height: initial;
            margin-top: 11px;
        }
    }

    .dorm-info-item + .dorm-info-item {
        margin-top: 16px;
    }
}

.attachment-comp-files {

    .attachment-comp-file {
        height: 56px;
        background: #f8f8f8;
        border: 1px solid #DCDEE0;
        border-radius: 4px;
        padding-left: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        +.attachment-comp-file {
            margin-top: 12px;
        }

        .attachment-comp-file-icon {
            text-align: center;
            flex-shrink: 0;
            position: relative;
            width: 36px;
            height: 36px;

            .attachment-icon-label {
                position: absolute;
                bottom: 6px;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 11px;
                color: #878787;
                line-height: 1;
            }

            .attachment-icon-img {
                object-fit: contain;
                display: inline-block;
            }

            .attachment-icon-img_img {
                width: 32px;
                height: 36px;
            }
            .attachment-icon-img_word {
                width: 36px;
                height: 36px;
            }
            .attachment-icon-img_txt {
                width: 36px;
                height: 36px;
            }
            .attachment-icon-img_zip {
                width: 32px;
                height: 36px;
            }
        }

        .attachment-comp-file-name {
            flex: 1;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 16px;
            white-space: nowrap;
        }

        .attachment-comp-download {
            padding: 0 16px;
            cursor: pointer;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }
    }
}
</style>
